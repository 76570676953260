<template>
  <div class="page page--restaurant animated_content">
    <SeqEntrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated" :title="restaurant.name" :background-image="restaurant.image_background ? restaurant.image_background.path : null" with-menu/>
      <RestaurantInfo :restaurant="restaurant" class="animate__animated"/>
      <div class="col-2 no_animation">
        <div class="col menu_container">
<!--          <h4>Menu</h4>-->
          <LoadingIndicator v-show="fetching.restaurantMenu"/>
          <SeqEntrance animation="animate__fadeInUp" tag="div" v-if="restaurantMenu.length > 0">
            <MenuHeader v-for="section in restaurantMenu" :label="section.label" :key="section.label" class="animate__animated">
              <SeqEntrance animation="animate__fadeInUp" tag="div">
                <MenuItem v-for="item in section.data" :item="item" :key="item.id" :restaurant-id="id" class="animate__animated"/>
              </SeqEntrance>
            </MenuHeader>
          </SeqEntrance>
        </div>
        <div class="col info_container">
<!--          <h4>Info</h4>-->
          <h4 style="padding-bottom: 4px"></h4>
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <InfoBox space v-if="restaurant.description" class="animate__animated">
              <p class="info_line">{{ restaurant.description }}</p>
            </InfoBox>
            <InfoBox space class="animate__animated">
              <h4 class="info_title">Adres</h4>
              <p class="info_line">{{ restaurant.street }} {{ restaurant.house_nr }}{{ restaurant.apartment_nr ? `/${restaurant.apartment_nr}` : '' }}</p>
              <p class="info_line">{{ restaurant.postcode }} {{ restaurant.city ? restaurant.city.name : '' }}</p>
            </InfoBox>
            <InfoBox space class="animate__animated">
              <h4 class="info_title">Godziny dostawy</h4>
              <div class="info_line">
                <p>Poniedziałek:</p>
                <p class="number-text">{{ restaurant.monday }}</p>
              </div>
              <div class="info_line">
                <p>Wtorek:</p>
                <p class="number-text">{{ restaurant.tuesday }}</p>
              </div>
              <div class="info_line">
                <p>Środa:</p>
                <p class="number-text">{{ restaurant.wednesday }}</p>
              </div>
              <div class="info_line">
                <p>Czwartek:</p>
                <p class="number-text">{{ restaurant.thursday }}</p>
              </div>
              <div class="info_line">
                <p>Piątek:</p>
                <p class="number-text">{{ restaurant.friday }}</p>
              </div>
              <div class="info_line">
                <p>Sobota:</p>
                <p class="number-text">{{ restaurant.saturday }}</p>
              </div>
              <div class="info_line">
                <p>Niedziela:</p>
                <p class="number-text">{{ restaurant.sunday }}</p>
              </div>
            </InfoBox>
            <InfoBox space class="animate__animated">
              <h4 class="info_title">Dostawa</h4>
              <div v-if="restaurant.min_price_order" class="info_line">
                <p>Minimalna kwota zamówienia:</p>
                <p>{{ restaurant.min_price_order.toFixed(2).toString().replace('.', ',') }} zł</p>
              </div>
              <div v-if="restaurant.min_cost_delivery && restaurant.max_cost_delivery" class="info_line">
                <p>Koszt dostawy:</p>
                <p v-if="restaurant.min_cost_delivery.toFixed(2) === restaurant.max_cost_delivery.toFixed(2)">{{ restaurant.max_cost_delivery.toFixed(2).toString().replace('.', ',') }} zł</p>
                <p v-else-if="restaurant.max_cost_delivery.toFixed(2) === '0.00'">Darmowa</p>
                <p v-else>
                  {{ restaurant.min_cost_delivery.toFixed(2).toString().replace('.', ',') }} - {{ restaurant.max_cost_delivery.toFixed(2).toString().replace('.', ',') }} zł
                </p>
              </div>
            </InfoBox>
            <InfoBox space v-if="restaurant.email || restaurant.phone" class="animate__animated">
              <h4 class="info_title">Dane kontaktowe</h4>
              <div v-if="restaurant.email" class="info_line">
                <p>E-mail:</p>
                <p><a :href="`mailto:${restaurant.email}`">{{ restaurant.email }}</a></p>
              </div>
              <div v-if="restaurant.phone" class="info_line">
                <p>Telefon:</p>
                <p>{{ restaurant.phone }}</p>
              </div>
            </InfoBox>
            <InfoBox space v-if="restaurant.payment_methods" class="animate__animated">
              <h4 class="info_title">Metody płatności</h4>
              <div class="payment_method_line">
                <div v-for="pm in restaurant.payment_methods" class="payment_method" :key="pm.id">
                  <img v-if="pm.image" :src="$asset(pm.image.path)" :alt="pm.image.original_name" class="payment_method_image"/>
                </div>
              </div>
            </InfoBox>
            <InfoBox space v-if="restaurant.company_name || restaurant.company_address || restaurant.nip" class="animate__animated">
              <h4 class="info_title">Informacje prawne</h4>
              <div class="info_line" v-if="restaurant.company_name">
                <p>{{ restaurant.company_name }}</p>
              </div>
              <div class="info_line" v-if="restaurant.company_address">
                <p>{{ restaurant.company_address }}</p>
              </div>
              <div class="info_line" v-if="restaurant.nip">
                <p>NIP: {{ restaurant.nip }}</p>
              </div>
            </InfoBox>
          </SeqEntrance>
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </SeqEntrance>
    <Drawer/>
    <SearchAddress/>
    <Cart/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import Drawer from "@/parts/Drawer";
import SearchAddress from "@/parts/SearchAddress";
import RestaurantInfo from "@/parts/RestaurantInfo";
import InfoBox from "@/parts/InfoBox";
import MenuHeader from "@/parts/MenuHeader";
import MenuItem from "@/parts/MenuItem";
import Cart from "@/parts/Cart";
import LoadingIndicator from "@/components/LoadingIndicator";
import SeqEntrance from "@/components/SeqEntrance";

export default {
  name: "Restaurant",
  components: {
    LoadingIndicator,
    Cart,
    MenuItem,
    MenuHeader,
    InfoBox,
    RestaurantInfo,
    PlainFooter,
    Drawer,
    SearchAddress,
    StickyHeader,
    SeqEntrance,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      restaurantMenu: [],
    };
  },
  computed: {
    ...mapGetters([
      'restaurantsMenu',
      'fetching',
    ]),
    restaurant() {
      return this.$store.getters.restaurant(this.id);
    },
  },
  watch: {
    restaurantsMenu: {
      handler(val) {
        if (val[this.id]) {
          this.restaurantMenu = [...val[this.id]];
        }
      },
      deep: true,
      immediate: true,
    }
  },
  created() {
    this.$store.dispatch('fetchRestaurantMenu', {restaurantId: this.id}).then(() => {
      this.restaurantMenu = [...this.restaurantsMenu[this.id]];
    });
  }

}
</script>

<style scoped lang="scss">
.flex-fill {
  flex: 1;
}
.col-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    //&:last-of-type {
    //  padding-left: 0;
    //}
    h4 {
      font-weight: 500;
    }
  }
  .menu_container {
    flex: 2;
  }
  .info_container {
    .info_title {
      display: flex;
      margin-top: 0;
      margin-bottom: 2px;
    }
    .info_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 2px;
      p {
        margin: 0;
        a {
          color: var(--text);
          text-decoration: none;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .payment_method_line {
      display: flex;
      flex-direction: row;
      margin-top: 4px;
    }
    .payment_method {
      width: 52px;
      height: 42px;
      margin-right: 4px;
    }
    .payment_method_image {
      width: 52px;
      height: 42px;
      object-fit: contain;
    }
  }
}
</style>
